/**
 * Services page
 */
/* Imports */
import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Herotype2 from "../components/herotype2"
import { graphql } from "gatsby"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/services.scss"

const ServicesPage = ({ data }) => {
  const dataPage = data.wpPage
  const context = React.useContext(I18nextContext)
  var cLangSlug = context.language
  var cLang = cLangSlug === "en" ? "En" : "Fr"

  var cAcf = "acfPageServices" + cLang
  var cTitleH1 = "titleH1Services" + cLang
  var cTitleService = "titleSectionServices" + cLang
  var cParagraph1Services = "paragraph1Services" + cLang
  var cParagraph2Services = "paragraph2Services" + cLang
  var cTitleExpertise = "titleSectionExpertise" + cLang
  var cParagraph1Expertise = "paragraph1Expertise" + cLang
  var cLogosWeb1 = "logosWeb1" + cLang
  var cParagraph2Expertise = "paragraph2Expertise" + cLang
  var cLogosWeb2 = "logosWeb2" + cLang
  var cTitlePrefooter = "titlePrefooter" + cLang
  var cParagraphPrefooter = "paragraphPrefooter" + cLang
  var cTextButtonPrefooter = "textButtonPrefooter" + cLang

  const dataAcf = dataPage[cAcf]
  const titleH1 = dataAcf[cTitleH1]
  const titleService = dataAcf[cTitleService]
  const paragrapServices1 = dataAcf[cParagraph1Services]
  const paragrapServices2 = dataAcf[cParagraph2Services]
  const titleExpertise = dataAcf[cTitleExpertise]
  const paragraphExpertise1 = dataAcf[cParagraph1Expertise]
  const logosWeb1 = dataAcf[cLogosWeb1]
  const paragraphExpertise2 = dataAcf[cParagraph2Expertise]
  const logosWeb2 = dataAcf[cLogosWeb2]
  const titlePrefooter = dataAcf[cTitlePrefooter]
  const paragraphPrefooter = dataAcf[cParagraphPrefooter]
  const textButtonPrefooter = dataAcf[cTextButtonPrefooter]

  return (
    <Layout>
      <Seo title={dataPage.seo.title} description={dataPage.seo.metaDesc} />
      <div className="page-services">
        <div className="container">
          <section className="section-hero-services section-herotype2">
            <Herotype2 h1title={titleH1} classImg="services" />
          </section>
          <section className="services">
            <h2>{titleService}</h2>
            <p>{paragrapServices1}</p>
            <p>{paragrapServices2}</p>
          </section>
          <div className="bar"></div>
          <section className="expertise">
            <h2>{titleExpertise}</h2>
            <div
              className="paragraph-expertise"
              dangerouslySetInnerHTML={{ __html: paragraphExpertise1 }}
            ></div>
            <div className="logos-languages">
              {logosWeb1.map((logos, index) => (
                <GatsbyImage
                  sizes="none"
                  objectFit="contain"
                  image={logos.logo.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                />
              ))}
            </div>
            <div
              className="paragraph-expertise"
              dangerouslySetInnerHTML={{ __html: paragraphExpertise2 }}
            ></div>
            <div className="logos-tech">
              {logosWeb2.map((logos, index) => (
                <GatsbyImage
                  objectFit="contain"
                  image={logos.logo.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                />
              ))}
            </div>
          </section>
        </div>
        <section className="prefooter">
          <div className="container">
            <h2>{titlePrefooter}</h2>
            <p>{paragraphPrefooter}</p>
            <a href="/contact/">{textButtonPrefooter}</a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

/* Graphql Queries */

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(
      slug: { regex: "/services/" }
      language: { slug: { eq: $language } }
    ) {
      acfPageServicesEn {
        titleH1ServicesEn
        titleSectionServicesEn
        paragraph1ServicesEn
        paragraph2ServicesEn
        titleSectionExpertiseEn
        paragraph1ExpertiseEn
        logosWeb1En {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 512
                )
              }
            }
          }
        }
        paragraph2ExpertiseEn
        logosWeb2En {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 512
                )
              }
            }
          }
        }
        titlePrefooterEn
        paragraphPrefooterEn
        textButtonPrefooterEn
      }
      acfPageServicesFr {
        titleH1ServicesFr
        titleSectionServicesFr
        paragraph1ServicesFr
        paragraph2ServicesFr
        titleSectionExpertiseFr
        paragraph1ExpertiseFr
        logosWeb1Fr {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 512
                )
              }
            }
          }
        }
        paragraph2ExpertiseFr
        logosWeb2Fr {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 512
                )
              }
            }
          }
        }
        titlePrefooterFr
        paragraphPrefooterFr
        textButtonPrefooterFr
      }
      seo {
        metaDesc
        title
      }
    }
  }
`

export default ServicesPage
